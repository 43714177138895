import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/nextjs';

import { useApiScopes } from '@common/ApiProvider';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import apiRequest from '@api/apiRequest';

import { FlightPlan, FlightPlanFile } from '@modules/FlightPlanSearch/types';
import { WeatherReport } from '@modules/FlightPlanWeather/types';

import { flightPlanUrl, weatherReportUrl, manualPlanUrl } from '@utils/apiUrls';

export interface DownloadPdfParams {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  flightPlan?: FlightPlan;
  weatherChart?: WeatherReport;
  file: FlightPlanFile;
  isManualPlan?: boolean;
}

export type DownloadStatusType = 'idle' | 'pending' | 'failure';

const usePdfDownloader = () => {
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatusType>('idle');
  const { instance } = useMsal();

  const pdfDownloadScopes = useApiScopes('flightPlan', ['flight.plan']);

  const downloadPdf = useCallback(
    async ({ event, flightPlan, file, isManualPlan }: DownloadPdfParams) => {
      const el = event.currentTarget;
      const fileName = file.fileName as string;

      if (el.href) {
        return;
      }

      event.preventDefault();

      setDownloadStatus('pending');

      try {
        const accessTokenResponse = await getAzureAccessToken(
          instance,
          pdfDownloadScopes
        );

        if (!accessTokenResponse) {
          throw new Error();
        }

        const url = flightPlan
          ? `${flightPlanUrl}/${flightPlan.flightPlanId}/pdfs/${file.fileName}`
          : isManualPlan
          ? `${manualPlanUrl}/${file.fileName}`
          : `${weatherReportUrl}/${file.reportName}`;

        const response = await apiRequest<{ downloadUrl: string }>({
          url,
          method: 'GET',
          headers: {
            Authorization: accessTokenResponse,
          },
        });

        el.href = response.downloadUrl;
        el.dispatchEvent(new MouseEvent('click'));

        if (isManualPlan) {
          document.getElementById(fileName)?.removeAttribute('href');
        }

        setDownloadStatus('idle');
      } catch (exception) {
        setDownloadStatus('failure');
        Sentry.captureException(exception);
      }
    },
    [instance, pdfDownloadScopes]
  );

  return {
    downloadPdf,
    downloadStatus,
  };
};

export default usePdfDownloader;
